import React, { Suspense, lazy } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Template from "./pages/template";
import NotFound from "./pages/NotFound ";
import Playground from "./components/playground";
import ResultsForm from "./exam_results/ResultsForm";
import AdvertWorksAndGoods from "./pages/advert_works_&_goods_2023_projects";
import News from "./pages/news";
import ResultsSummary from "./exam_results/ResultsSummary";
import ResultsDashBoard from "./exam_results/ResultsDashBoard";
import { StateProvider } from "./utils/app-state-context";
import LoadingSkeleton from "./component3/LoadingSkeleton"
import ReactGA from 'react-ga';

const Home = lazy(() => import('./pages/home'));
const About = lazy(() => import('./pages/about'));
const CapacityBuilding = lazy(() => import('./pages/capacity-building'));
const Research = lazy(() => import('./pages/research'));
const PolicyBrief = lazy(() => import('./pages/policy-brief'));
const Newspaper = lazy(() => import('./pages/newspaper'));
const TechnicalReport = lazy(() => import('./pages/technical-report'));
const Contact = lazy(() => import('./pages/contact'));
const AdvertEoi = lazy(() => import('./pages/advert-eoi-2023-projects'));
const PSRTest = lazy(() => import('./pages/PSR'));
const StaffPubAdmin = lazy(() => import('./pages/StaffPubAdmin'));
const UpcomingEvents = lazy(() => import('./pages/UpcomingEvents'));
const LatestNews = lazy(() => import('./pages/LatestNews'));
const PastEvents = lazy(() => import('./pages/PastEvents'));
const Gstic = lazy(() => import('./pages/Gstic'));
const VewSeminar = lazy(() => import('./pages/vewSeminar'));

const LoginResearcher=lazy(()=>import("./research-ouput/pages/LoginResearcher"))
const ResearcherDashboard=lazy(()=>import("./research-ouput/pages/ResearcherDashboard"))
const SubmitOutputs=lazy(()=>import("./research-ouput/pages/SubmitOutputs"))
const Register=lazy(()=>import("./research-ouput/pages/Register"))
const UserDetails=lazy(()=>import("./research-ouput/pages/UserDetails"))
const RegistrationSuccessful=lazy(()=>import("./research-ouput/pages/RegistrationSuccessful"))
const EditOutputs=lazy(()=>import("./research-ouput/pages/EditOutputs"))
const ProfileUpdate=lazy(()=>import("./research-ouput/pages/ProfileUpdate"))
const ProfilePicture=lazy(()=>import("./research-ouput/pages/ProfilePicture"))
const ProtectedRoute=lazy(()=>import("./research-ouput/components/ProtectedRoute"))

// Initialize React Ga with your tracking ID
ReactGA.initialize("362942583");

const initialState = {
  results: [],
  filter: { courseCode: "", session: "", centre: "" },
  courseCode: "",
  session: "",
  centre: "",
  editRecord: {},
  editMode: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "POPULATERESULTS":
      return { ...state, ...action.payload };
    case "FILTER":
      return { ...state.filter, ...action.payload };
    case "COURSECODE":
      return { ...state, ...action.payload };
    case "SESSION":
      return { ...state, ...action.payload };
    case "CENTRE":
      return { ...state, ...action.payload };
    case "EDIT-RESULT":
      return { ...state.editRecord, ...action.payload };

    default:
      return state;
  }
};
export default function App() {
  
  return (
    <Router>
      <StateProvider reducer={reducer} initialState={initialState}>
        <Suspense fallback={<LoadingSkeleton/>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/capacity-building" element={<CapacityBuilding />} />
            <Route path="/research" element={<Research />} />
            <Route path="/about" element={<About />} />
            <Route path="/policy-brief" element={<PolicyBrief />} />
            <Route path="/staff-publications-admin" element={<StaffPubAdmin />} />
            <Route path="/newsletter" element={<Newspaper />} />
            <Route path="/playground" element={<Playground />} />
            <Route path="/technical-reports" element={<TechnicalReport />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/template" element={<Template />} />
            <Route path="/results-form" element={<ResultsForm />} />
            <Route path="/advert-eoi-2023-projects" element={<AdvertEoi />} />
            <Route
              path="/advert_works_&_goods_2023_projects"
              element={<AdvertWorksAndGoods />}
            />
            <Route
              path="/psrtest"
              element={<PSRTest />}
            />
            <Route
              path="/news/nacetem-partners-futmina"
              element={<News/>}
            />
            <Route
              path="/upcoming-events"
              element={<UpcomingEvents/>}
            />
            <Route
              path="/latest-news"
              element={<LatestNews/>}
            />
            <Route
              path="/past-events"
              element={<PastEvents/>}
            />
             <Route
              path="/g-stic"
              element={<Gstic/>}
            />
              <Route
              path="/view-seminar"
              element={<VewSeminar/>}
            />
            
            <Route path="/results-summary" element={<ResultsSummary />} />
            <Route path="/results-dashboard" element={<ResultsDashBoard />} />

            <Route path="/login/reseacher" element={<LoginResearcher />} />
            <Route path="/researcher-dashboard" element={
              <ResearcherDashboard />
            } />
            <Route path="/researcher/submit-output" element={
              <SubmitOutputs /> 
            } />
            <Route path="/researcher/register" element={<Register />} />
            <Route path="/researcher-details" element={<UserDetails />} />
            <Route path="/registration-successful" element={<RegistrationSuccessful />} />
            <Route path="/edit-output" element={
            <EditOutputs /> 
            } />
            <Route path="/edit-profile" element={
            <ProfileUpdate />
            } />
            <Route path="/profile-picture" element={
            <ProfilePicture />
            } />
            
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </StateProvider>
    </Router>
  );
}
{/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Dashboard />
            
          }
        /> */}